
import React, { Component, useState, useRef } from "react";
import { json, useNavigate } from "react-router-dom";
import Icon from "components/Icon";
import { FilePond, registerPlugin } from 'react-filepond';
import 'filepond/dist/filepond.min.css';
import { read, utils } from 'xlsx';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import { Dropdown } from 'primereact/dropdown';
import { FloatLabel } from 'primereact/floatlabel';
import { InputText } from 'primereact/inputtext';
import useReports from "services/ReportService";
import { useOktaAuth } from "@okta/okta-react";
// import { FileUpload } from 'primereact/fileupload';
// import "primereact/resources/themes/lara-light-cyan/theme.css";
import templateFile from "../../assets/architect_template.xlsx";
import { ProgressBar } from 'primereact/progressbar';

import Button from "../Button";
import DownloadButton from "../DownloadButton";


registerPlugin(FilePondPluginFileValidateType);

export default function Form({
  clients,
  closeModal
}) {
  const [uploadedFile, setUploadedFile] = useState();
  const [errorMessage, setErrorMessage] = useState('');
  const createReportDisabled = uploadedFile == undefined || errorMessage !== '';

  const version = 2;

  const testing = false;

  const sheets = [
    "Performance",
    "CRO",
    "STAT",
    "KW Trends",
  ];

  const handleFileLoad = (error, files) => {
    if (files) {
      try {
        const file = files.file;
        setUploadedFile(file);
        const reader = new FileReader();
        reader.onload = (e) => {
          const data = new Uint8Array(e.target.result);
          const workbook = read(data, { type: 'array' });
          if (!workbook.SheetNames.includes("info") || utils.sheet_to_json(workbook.Sheets["info"])[0].version !== version) {
            setErrorMessage('Outdated template, please download and populate the latest version.');
            return;
          }
          if (!sheets.some(item => workbook.SheetNames.includes(item))) {
            setErrorMessage('No relevant sheets found.');
            return;
          }
          else {
            setErrorMessage('');
          }

          var DecPrec = (function() {
            if (Number.EPSILON === undefined) {
              Number.EPSILON = Math.pow(2, -52);
            }
            if (Math.sign === undefined) {
              Math.sign = function(x) {
                return ((x > 0) - (x < 0)) || +x;
              };
            }
            return {
              // Decimal round (half away from zero)
              round: function(num, decimalPlaces) {
                var p = Math.pow(10, decimalPlaces || 0);
                var n = (num * p) * (1 + Number.EPSILON);
                return Math.round(n) / p;
              },
              // Decimal ceil
              ceil: function(num, decimalPlaces) {
                var p = Math.pow(10, decimalPlaces || 0);
                var n = (num * p) * (1 - Math.sign(num) * Number.EPSILON);
                return Math.ceil(n) / p;
              },
              // Decimal floor
              floor: function(num, decimalPlaces) {
                var p = Math.pow(10, decimalPlaces || 0);
                var n = (num * p) * (1 + Math.sign(num) * Number.EPSILON);
                return Math.floor(n) / p;
              },
              // Decimal trunc
              trunc: function(num, decimalPlaces) {
                return (num < 0 ? this.ceil : this.floor)(num, decimalPlaces);
              },
              // Format using fixed-point notation
              toFixed: function(num, decimalPlaces) {
                return this.round(num, decimalPlaces).toFixed(decimalPlaces);
              }
              };
          })();

          if (testing) {
            let excelData = {};

            let performance = utils.sheet_to_json(workbook.Sheets["Performance"]);
            const stat = utils.sheet_to_json(workbook.Sheets["STAT"]);
            const keywordInsights = utils.sheet_to_json(workbook.Sheets["Keyword Insights"]);
            const cro = utils.sheet_to_json(workbook.Sheets["CRO"]);
            const ctrAnalysis = utils.sheet_to_json(workbook.Sheets["CTR Analysis"]);
            let serpReport = utils.sheet_to_json(workbook.Sheets["SERP Report"]);
            let serpIndex = utils.sheet_to_json(workbook.Sheets["SERP Index"]);
            let kwTrends = utils.sheet_to_json(workbook.Sheets["KW Trends"]);

            function vlookup(data, colToMatch, valToMatch, col, defaultValue) {
              const result = data.find(row => row[colToMatch] === valToMatch);
              return result ? result[col] || defaultValue : defaultValue;
            }

            function calculateOpportunity(clientRanking) {
              if (clientRanking <= 3) {
                return "Maintain";
              } else if (clientRanking <= 10) {
                return "Striking Distance";
              } else if (clientRanking <= 20) {
                return "Nudge";
              } else if (clientRanking <= 30) {
                return "Quick Win";
              } else if (clientRanking <= 199) {
                return "Work Needed";
              } else {
                return "-";
              }
            }

            function calculateMaxDifference(num1, num2) {
              try {
                if (isNaN(num1) || isNaN(num2)) {
                  throw new Error('Invalid number');
                }
                return Math.max(num1 - num2, 0);
              } catch (error) {
                return "-";
              }
            }

            const ctr1 = vlookup(ctrAnalysis, "Rank", 1, "Mobile CTR", 0);
            const ctr2 = vlookup(ctrAnalysis, "Rank", 2, "Mobile CTR", 0);
            const ctr3 = vlookup(ctrAnalysis, "Rank", 3, "Mobile CTR", 0);
            const ctr4 = vlookup(ctrAnalysis, "Rank", 4, "Mobile CTR", 0);
            const ctr5 = vlookup(ctrAnalysis, "Rank", 5, "Mobile CTR", 0);

            let keywordGaps = [
              {
                "label": "Missing",
                "gaps": 0
              },
              {
                "label": "Weak",
                "gaps": 0
              },
              {
                "label": "Strong",
                "gaps": 0
              },
              {
                "label": "Shared",
                "gaps": 0
              },
              {
                "label": "Untapped",
                "gaps": 0
              }
            ];

            performance.forEach(row => {
              row["Avg. Monthly Volume"] = vlookup(stat, "Keyword", row["Keyword"], "Search Vol", "-");
              row["Client Ranking"] = vlookup(stat, "Keyword", row["Keyword"], "Client Base Rank", "-");
              row["Ranking URL"] = vlookup(stat, "Keyword", row["Keyword"], "Client URL", "-");
              row["Weighted Sum"] = row["Avg. Monthly Volume"] !== "-" && row["Client Ranking"] !== "-" ? row["Avg. Monthly Volume"] * row["Client Ranking"] : "-";
              row["Search Intent"] = vlookup(keywordInsights, "Keyword", row["Keyword"], "Dominant Intent", "-");
              row["Article Page Type"] = vlookup(keywordInsights, "Keyword", row["Keyword"], "Article", "-");
              row["PDP/PLP Page Type"] = vlookup(keywordInsights, "Keyword", row["Keyword"], "Product/Category", "-");
              row["Other Page Type"] = vlookup(keywordInsights, "Keyword", row["Keyword"], "Other page type", "-");
              row["Opportunity"] = calculateOpportunity(row["Client Ranking"] || 200);

              row["URL CVR"] = vlookup(cro, "URL", row["Ranking URL"], "GA Transaction CVR", 0);
              row["URL Bounce Rate"] = vlookup(cro, "URL", row["Ranking URL"], "GA Bounce Rate", 0);
              row["URL Avg Session Duration"] = vlookup(cro, "URL", row["Ranking URL"], "GA Avg Session Duration", 0);
              row["URL Exit Rate"] = vlookup(cro, "URL", row["Ranking URL"], "GA Exit Rate", 0);
              row["URL Page Value"] = vlookup(cro, "URL", row["Ranking URL"], "GA Page Value", 0);
              row["URL AOV"] = vlookup(cro, "URL", row["Ranking URL"], "GA Transaction AOV", 0);

              row["Competitor #1 Ranking"] = vlookup(stat, "Keyword", row["Keyword"], "Competitor 1 Base Rank", "-");
              row["Competitor #1 Ranking URL"] = vlookup(stat, "Keyword", row["Keyword"], "Competitor 1 URL", "-");
              row["Competitor #1 Current CTR%"] = vlookup(ctrAnalysis, "Rank", row["Competitor #1 Ranking"], "Mobile CTR", 0);
              row["Competitor #1 Clicks"] = row["Competitor #1 Current CTR%"] !== "-" && row["Avg. Monthly Volume"] !== "-" ? row["Competitor #1 Current CTR%"] * row["Avg. Monthly Volume"] * 12 : "-";
              row["Competitor #1 Revenue"] = row["Benchmark CVR%"] !== "-" && row["Competitor #1 Clicks"] !== "-" && row["Benchmark AOV"] !== "-" ? row["Benchmark CVR%"] * row["Competitor #1 Clicks"] * row["Benchmark AOV"] : "-";

              row["Competitor #2 Ranking"] = vlookup(stat, "Keyword", row["Keyword"], "Competitor 2 Base Rank", "-");
              row["Competitor #2 Ranking URL"] = vlookup(stat, "Keyword", row["Keyword"], "Competitor 2 URL", "-");
              row["Competitor #2 Current CTR%"] = vlookup(ctrAnalysis, "Rank", row["Competitor #2 Ranking"], "Mobile CTR", 0);
              row["Competitor #2 Clicks"] = row["Competitor #2 Current CTR%"] !== "-" && row["Avg. Monthly Volume"] !== "-" ? row["Competitor #2 Current CTR%"] * row["Avg. Monthly Volume"] * 12 : "-";
              row["Competitor #2 Revenue"] = row["Benchmark CVR%"] !== "-" && row["Competitor #2 Clicks"] !== "-" && row["Benchmark AOV"] !== "-" ? row["Benchmark CVR%"] * row["Competitor #2 Clicks"] * row["Benchmark AOV"] : "-";

              row["Competitor #3 Ranking"] = vlookup(stat, "Keyword", row["Keyword"], "Competitor 3 Base Rank", "-");
              row["Competitor #3 Ranking URL"] = vlookup(stat, "Keyword", row["Keyword"], "Competitor 3 URL", "-");
              row["Competitor #3 Current CTR%"] = vlookup(ctrAnalysis, "Rank", row["Competitor #3 Ranking"], "Mobile CTR", 0);
              row["Competitor #3 Clicks"] = row["Competitor #3 Current CTR%"] !== "-" && row["Avg. Monthly Volume"] !== "-" ? row["Competitor #3 Current CTR%"] * row["Avg. Monthly Volume"] * 12 : "-";
              row["Competitor #3 Revenue"] = row["Benchmark CVR%"] !== "-" && row["Competitor #3 Clicks"] !== "-" && row["Benchmark AOV"] !== "-" ? row["Benchmark CVR%"] * row["Competitor #3 Clicks"] * row["Benchmark AOV"] : "-";

              row["Competitor #4 Ranking"] = vlookup(stat, "Keyword", row["Keyword"], "Competitor 4 Base Rank", "-");
              row["Competitor #4 Ranking URL"] = vlookup(stat, "Keyword", row["Keyword"], "Competitor 4 URL", "-");
              row["Competitor #4 Current CTR%"] = vlookup(ctrAnalysis, "Rank", row["Competitor #4 Ranking"], "Mobile CTR", 0);
              row["Competitor #4 Clicks"] = row["Competitor #4 Current CTR%"] !== "-" && row["Avg. Monthly Volume"] !== "-" ? row["Competitor #4 Current CTR%"] * row["Avg. Monthly Volume"] * 12 : "-";
              row["Competitor #4 Revenue"] = row["Benchmark CVR%"] !== "-" && row["Competitor #4 Clicks"] !== "-" && row["Benchmark AOV"] !== "-" ? row["Benchmark CVR%"] * row["Competitor #4 Clicks"] * row["Benchmark AOV"] : "-";

              row["Current CTR%"] = vlookup(ctrAnalysis, "Rank", row["Client Ranking"], "Mobile CTR", 0);
              row["Clicks"] = row["Current CTR%"] !== "-" && row["Avg. Monthly Volume"] !== "-" ? row["Current CTR%"] * row["Avg. Monthly Volume"] * 12 : "-";
              row["Revenue"] = row["Benchmark CVR%"] !== "-" && row["Clicks"] !== "-" && row["Benchmark AOV"] !== "-" ? row["Benchmark CVR%"] * row["Clicks"] * row["Benchmark AOV"] : "-";

              row["Expected Yearly Traffic In #1"] = row["Avg. Monthly Volume"] !== "-" ? row["Avg. Monthly Volume"] * ctr1 * 12 : "-";
              row["Expected Yearly Traffic Top 3"] = row["Avg. Monthly Volume"] !== "-" ? (row["Avg. Monthly Volume"] * ctr1 + row["Avg. Monthly Volume"] * ctr2 + row["Avg. Monthly Volume"] * ctr3) * 4 : "-";
              row["Expected Yearly Traffic Top 5"] = row["Avg. Monthly Volume"] !== "-" ? (row["Avg. Monthly Volume"] * ctr1 + row["Avg. Monthly Volume"] * ctr2 + row["Avg. Monthly Volume"] * ctr3 + row["Avg. Monthly Volume"] * ctr4 + row["Avg. Monthly Volume"] * ctr5) * 2.4 : "-";
              row["Yearly Traffic Gain In #1"] = calculateMaxDifference(row["Expected Yearly Traffic In #1"], row["Clicks"])
              row["Yearly Traffic Gain Top 3"] = calculateMaxDifference(row["Expected Yearly Traffic Top 3"], row["Clicks"])
              row["Yearly Traffic Gain Top 5"] = calculateMaxDifference(row["Expected Yearly Traffic Top 5"], row["Clicks"])
              row["Yearly Revenue Gain In #1"] = row["Benchmark CVR%"] !== "-" && row["Yearly Traffic Gain In #1"] !== "-" && row["Benchmark AOV"] !== "-" ? row["Benchmark CVR%"] * row["Yearly Traffic Gain In #1"] * row["Benchmark AOV"] : "-";
              row["Yearly Revenue Gain Top 3"] = row["Benchmark CVR%"] !== "-" && row["Yearly Traffic Gain Top 3"] !== "-" && row["Benchmark AOV"] !== "-" ? row["Benchmark CVR%"] * row["Yearly Traffic Gain Top 3"] * row["Benchmark AOV"] : "-";
              row["Yearly Revenue Gain Top 5"] = row["Benchmark CVR%"] !== "-" && row["Yearly Traffic Gain Top 5"] !== "-" && row["Benchmark AOV"] !== "-" ? row["Benchmark CVR%"] * row["Yearly Traffic Gain Top 5"] * row["Benchmark AOV"] : "-";

              if (
                row["Client Ranking"] > 100 &&
                row["Competitor #1 Ranking"] < 101 &&
                row["Competitor #2 Ranking"] < 101 &&
                row["Competitor #3 Ranking"] < 101 &&
                row["Competitor #4 Ranking"] < 101
              ) {
                keywordGaps[0].gaps++;
              }

              if (
                row["Client Ranking"] > 100 &&
                row["Client Ranking"] > row["Competitor #1 Ranking"] &&
                row["Client Ranking"] > row["Competitor #2 Ranking"] &&
                row["Client Ranking"] > row["Competitor #3 Ranking"] &&
                row["Client Ranking"] > row["Competitor #4 Ranking"]
              ) {
                keywordGaps[1].gaps++;
              }

              if (
                row["Client Ranking"] < row["Competitor #1 Ranking"] &&
                row["Client Ranking"] < row["Competitor #2 Ranking"] &&
                row["Client Ranking"] < row["Competitor #3 Ranking"] &&
                row["Client Ranking"] < row["Competitor #4 Ranking"]
              ) {
                keywordGaps[2].gaps++;
              }

              if (
                row["Client Ranking"] < 101 &&
                row["Competitor #1 Ranking"] < 101 &&
                row["Competitor #2 Ranking"] < 101 &&
                row["Competitor #3 Ranking"] < 101 &&
                row["Competitor #4 Ranking"] < 101
              ) {
                keywordGaps[3].gaps++;
              }

              if (
                row["Client Ranking"] > 101 && (
                row["Competitor #1 Ranking"] < 101 ||
                row["Competitor #2 Ranking"] < 101 ||
                row["Competitor #3 Ranking"] < 101 ||
                row["Competitor #4 Ranking"] < 101)
              ) {
                keywordGaps[4].gaps++;
              }
            });

            const isValidUrl = url => {
              try {
                return Boolean(new URL(url)); 
              }
              catch(e) {
                return false; 
              }
            }

            serpReport.forEach(row => {
              row["Result Type"] = vlookup(serpIndex, "SERP Feature", row["SERP Feature"], "Result Type", "-");
              row["Client Owned"] = rootDomain !== "" && row["Ranking URL"].includes(rootDomain) ? 1 : 0;
              const url = row["Ranking URL"].startsWith('www.') ? 'https://' + row["Ranking URL"] : row["Ranking URL"]
              row["Domain"] = isValidUrl(url) ? new URL(url).hostname : "-";
            });

            kwTrends.forEach(row => {
              row["Avg. Monthly Volume"] = vlookup(stat, "Keyword", row["Keyword"], "Search Vol", "-");
              row["Tag 1"] = vlookup(performance, "Keyword", row["Keyword"], "Tag 1", "-");
              row["Tag 2"] = vlookup(performance, "Keyword", row["Keyword"], "Tag 2", "-");
              row["Tag 3"] = vlookup(performance, "Keyword", row["Keyword"], "Tag 3", "-");
              row["Previous 12 Months"] = row["Year 1 Month 1"] + row["Year 1 Month 2"] + row["Year 1 Month 3"] + row["Year 1 Month 4"] + row["Year 1 Month 5"] + row["Year 1 Month 6"] + row["Year 1 Month 7"] + row["Year 1 Month 8"] + row["Year 1 Month 9"] + row["Year 1 Month 10"] + row["Year 1 Month 11"] + row["Year 1 Month 12"];
              row["Last 12 Months"] = row["Year 2 Month 1"] + row["Year 2 Month 2"] + row["Year 2 Month 3"] + row["Year 2 Month 4"] + row["Year 2 Month 5"] + row["Year 2 Month 6"] + row["Year 2 Month 7"] + row["Year 2 Month 8"] + row["Year 2 Month 9"] + row["Year 2 Month 10"] + row["Year 2 Month 11"] + row["Year 2 Month 12"];
            });

            // CP - Keyword Gaps
            if (keywordGaps.some(item => item.gaps > 0)) {
              keywordGaps.sort((a, b) => b.gaps - a.gaps);
              excelData["cp_keyword_gaps"] = keywordGaps;
            }

            // PD - Category Rankings
            try {
              const tagRankings = performance.reduce((acc, obj) => {
                const tag = obj["Tag 1"];
                const volume = parseFloat(obj["Avg. Monthly Volume"]);
                const weightedSum = parseFloat(obj["Weighted Sum"]);
                if (!isNaN(volume) && !isNaN(weightedSum) && volume !== 0) {
                  const rank = weightedSum / volume;
                  if (!acc[tag]) {
                    acc[tag] = { totalRank: 0, count: 0 };
                  }
                  acc[tag].totalRank += rank;
                  acc[tag].count += 1;
                }
                return acc;
              }, {});
              const averagedRankings = Object.keys(tagRankings).map(tag => ({
                category: tag,
                ranking: DecPrec.round(tagRankings[tag].totalRank / tagRankings[tag].count)
              }));
              const sortedRankings = averagedRankings.sort((a, b) => a.ranking - b.ranking);
              if (sortedRankings.length > 0) {
                excelData["pd_best_ranking_categories"] = sortedRankings.slice(0, 10);
                excelData["pd_worst_ranking_categories"] = sortedRankings.slice(-10).reverse();
              }
            } catch (e) {
              console.error("PD - Category Rankings", e.message)
            }

            // PD - Traffic Opps
            try {
              let pd_traffic_opps;
              pd_traffic_opps = Object.values(performance.map(({ "Yearly Traffic Gain In #1": traffic_gain, "Tag 1": category }) => ({ traffic_gain, category }))
                .reduce((acc, { traffic_gain, category }) => {
                  if (!acc[category]) {
                    acc[category] = { category, traffic_gain: 0 };
                  }
                  if (!isNaN(traffic_gain)) {
                    acc[category].traffic_gain += traffic_gain;
                  }
                  return acc;
                }, {})
              )
              pd_traffic_opps.forEach(item => {
                item.traffic_gain = DecPrec.round(item.traffic_gain);
              });
              pd_traffic_opps = pd_traffic_opps.sort((a, b) => b.traffic_gain - a.traffic_gain).slice(0, 10);
              if (pd_traffic_opps.length > 0) {
                excelData["pd_traffic_opps"] = pd_traffic_opps;
              }
            } catch (e) {
              console.error("PD - Traffic Opps", e.message)
            }

            // PD - Revenue Opps
            try {
              let revenue_opps;
              revenue_opps = Object.values(performance.map(({ "Yearly Revenue Gain In #1": revenue_gain, "Tag 1": category }) => ({ revenue_gain, category }))
                .reduce((acc, { revenue_gain, category }) => {
                  if (!acc[category]) {
                    acc[category] = { category, revenue_gain: 0 };
                  }
                  if (!isNaN(revenue_gain)) {
                    acc[category].revenue_gain += revenue_gain;
                  }
                  return acc;
                }, {})
              )
              revenue_opps.forEach(item => {
                item.revenue_gain = DecPrec.round(item.revenue_gain);
              });
              revenue_opps = revenue_opps.sort((a, b) => b.revenue_gain - a.revenue_gain).slice(0, 10);
              if (revenue_opps.length > 0) {
                excelData["pd_revenue_opps"] = revenue_opps;
              }
            } catch (e) {
              console.error("PD - Revenue Opps", e.message)
            }

            // PD - Rank Opps Grouped
            try {
              const total = performance.length;
              const opportunityCount = performance.reduce((acc, obj) => {
                const opportunity = obj.Opportunity;
                acc[opportunity] = (acc[opportunity] || 0) + 1;
                return acc;
              }, {});
              const pdRankingOpps = Object.keys(opportunityCount).map(key => ({
                category: key,
                opp: parseFloat(DecPrec.toFixed((opportunityCount[key] / total) * 100, 2))
              }));
              if (pdRankingOpps.length > 0) {
                excelData["pd_ranking_opps"] = pdRankingOpps;
              }
            } catch (e) {
              console.error("PD - Revenue Opps", e.message)
            }

            // PD - Ranking Per URL
            try {
              const urlCount = performance.reduce((acc, obj) => {
                const url = obj["Ranking URL"];
                acc[url] = (acc[url] || 0) + 1;
                return acc;
              }, {});
              const sortedUrls = Object.keys(urlCount).map(key => ({
                category: key,
                ranking: urlCount[key]
              })).sort((a, b) => b.ranking - a.ranking);
              const pdUrlRanking = sortedUrls.slice(0, 10);
              if (pdUrlRanking.length > 0) {
                excelData["pd_url_ranking"] = pdUrlRanking;
              }
            } catch (e) {
              console.error("PD - Ranking Per URL", e.message)
            }

            // PD - Biggest Search Category
            try {
              const tagVolumeSum = performance.reduce((acc, obj) => {
                const tag = obj["Tag 1"];
                const volume = parseFloat(obj["Avg. Monthly Volume"]);
                if (!isNaN(volume)) {
                  if (!acc[tag]) {
                    acc[tag] = 0;
                  }
                  acc[tag] += volume;
                }
                return acc;
              }, {});
              const sortedTags = Object.keys(tagVolumeSum).map(tag => ({
                category: tag,
                volume: tagVolumeSum[tag]
              })).sort((a, b) => b.volume - a.volume);
              const biggestSearchCategories = sortedTags.slice(0, 10);
              if (biggestSearchCategories.length > 0) {
                excelData["pd_biggest_search_categories"] = biggestSearchCategories;
              }
            } catch (e) {
              console.error("PD - Biggest Search Category", e.message)
            }

            // PD - Keyword Intent
            try {
              const intentStats = performance.reduce((acc, obj) => {
                const intent = obj["Search Intent"];
                const clicks = parseFloat(obj["Clicks"]);
                const traffic = parseFloat(obj["Expected Yearly Traffic In #1"]);
                const keyword = obj["Keyword"];
                if (!acc[intent]) {
                  acc[intent] = {
                    totalClicks: 0,
                    totalTraffic: 0,
                    uniqueKeywords: new Set()
                  };
                }
                if (!isNaN(clicks)) acc[intent].totalClicks += clicks;
                if (!isNaN(traffic)) acc[intent].totalTraffic += traffic;
                acc[intent].uniqueKeywords.add(keyword);
                return acc;
              }, {});
              const keywordIntent = Object.keys(intentStats).map(intent => ({
                intent,
                clicks: DecPrec.round(intentStats[intent].totalClicks),
                traffic: DecPrec.round(intentStats[intent].totalTraffic),
                keywords: intentStats[intent].uniqueKeywords.size
              }));
              if (keywordIntent.length > 0) {
                excelData["pd_keyword_intent"] = keywordIntent;
              }
            } catch (e) {
              console.error("PD - Keyword Intent", e.message)
            }

            // PD - Page Template
            try {
              const pageStats = performance.reduce((acc, obj) => {
                const page = obj["Page Template"] || "(blank)";
                const clicks = parseFloat(obj["Clicks"]);
                const traffic = parseFloat(obj["Expected Yearly Traffic In #1"]);
                const keyword = obj["Keyword"];
                if (!acc[page]) {
                  acc[page] = {
                    totalClicks: 0,
                    totalTraffic: 0,
                    uniqueKeywords: new Set()
                  };
                }
                if (!isNaN(clicks)) acc[page].totalClicks += clicks;
                if (!isNaN(traffic)) acc[page].totalTraffic += traffic;
                acc[page].uniqueKeywords.add(keyword);
                return acc;
              }, {});
              const pageTemplate = Object.keys(pageStats).map(page => ({
                page,
                clicks: DecPrec.round(pageStats[page].totalClicks),
                traffic: DecPrec.round(pageStats[page].totalTraffic),
                keywords: pageStats[page].uniqueKeywords.size
              }));
              if (pageTemplate.length > 0) {
                excelData["pd_page_template"] = pageTemplate;
              }
            } catch (e) {
              console.error("PD - Page Template", e.message)
            }

            // RTO - Traffic by Cat
            try {
              const trafficStats = performance.reduce((acc, obj) => {
                const category = obj["Tag 1"];
                const clicks = parseFloat(obj["Clicks"]);
                const traffic = parseFloat(obj["Yearly Traffic Gain In #1"]);
                if (!acc[category]) {
                  acc[category] = {
                    totalClicks: 0,
                    totalTraffic: 0,
                  };
                }
                if (!isNaN(clicks)) acc[category].totalClicks += clicks;
                if (!isNaN(traffic)) acc[category].totalTraffic += traffic;
                return acc;
              }, {});
              const trafficSorted = Object.keys(trafficStats).map(category => ({
                category,
                clicks: DecPrec.round(trafficStats[category].totalClicks),
                traffic_gain: DecPrec.round(trafficStats[category].totalTraffic),
              })).sort((a, b) => b.clicks - a.clicks);
              if (trafficSorted.length > 0) {
                excelData["rto_traffic_category"] = trafficSorted.slice(0, 10);
              }
            } catch (e) {
              console.error("RTO - Traffic by Cat", e.message)
            }

            // RTO - Traffic by Sub-Cat
            try {
              const trafficStats = performance.reduce((acc, obj) => {
                const category = obj["Tag 2"];
                const clicks = parseFloat(obj["Clicks"]);
                const traffic = parseFloat(obj["Yearly Traffic Gain In #1"]);
                if (!acc[category]) {
                  acc[category] = {
                    totalClicks: 0,
                    totalTraffic: 0,
                  };
                }
                if (!isNaN(clicks)) acc[category].totalClicks += clicks;
                if (!isNaN(traffic)) acc[category].totalTraffic += traffic;
                return acc;
              }, {});
              const trafficSorted = Object.keys(trafficStats).map(category => ({
                category,
                clicks: DecPrec.round(trafficStats[category].totalClicks),
                traffic_gain: DecPrec.round(trafficStats[category].totalTraffic),
              })).sort((a, b) => b.clicks - a.clicks);
              if (trafficSorted.length > 0) {
                excelData["rto_traffic_sub"] = trafficSorted.slice(0, 10);
              }
            } catch (e) {
              console.error("RTO - Traffic by Sub-Cat", e.message)
            }

            // RTO - Traffic by Type
            try {
              const trafficStats = performance.reduce((acc, obj) => {
                const category = obj["Tag 3"];
                const clicks = parseFloat(obj["Clicks"]);
                const traffic = parseFloat(obj["Yearly Traffic Gain In #1"]);
                if (!acc[category]) {
                  acc[category] = {
                    totalClicks: 0,
                    totalTraffic: 0,
                  };
                }
                if (!isNaN(clicks)) acc[category].totalClicks += clicks;
                if (!isNaN(traffic)) acc[category].totalTraffic += traffic;
                return acc;
              }, {});
              const trafficSorted = Object.keys(trafficStats).map(category => ({
                category,
                clicks: DecPrec.round(trafficStats[category].totalClicks),
                traffic_gain: DecPrec.round(trafficStats[category].totalTraffic),
              })).sort((a, b) => b.clicks - a.clicks);
              if (trafficSorted.length > 0) {
                excelData["rto_traffic_type"] = trafficSorted.slice(0, 10);
              }
            } catch (e) {
              console.error("RTO - Traffic by Type", e.message)
            }

            // RTO - Revenue by Cat
            try {
              const revenueStats = performance.reduce((acc, obj) => {
                const category = obj["Tag 1"];
                const revenue = parseFloat(obj["Revenue"]);
                const revenueGain = parseFloat(obj["Yearly Revenue Gain In #1"]);
                if (!acc[category]) {
                  acc[category] = {
                    totalRevenue: 0,
                    totalRevenueGain: 0,
                  };
                }
                if (!isNaN(revenue)) acc[category].totalRevenue += revenue;
                if (!isNaN(revenueGain)) acc[category].totalRevenueGain += revenueGain;
                return acc;
              }, {});
              const revenueSorted = Object.keys(revenueStats).map(category => ({
                category,
                revenue: DecPrec.round(revenueStats[category].totalRevenue),
                revenue_gain: DecPrec.round(revenueStats[category].totalRevenueGain),
              })).sort((a, b) => b.revenue - a.revenue);
              if (revenueSorted.length > 0) {
                excelData["rto_revenue_category"] = revenueSorted.slice(0, 10);
              }
            } catch (e) {
              console.error("RTO - Revenue by Cat", e.message)
            }

            // RTO - Revenue by Sub-Cat
            try {
              const revenueStats = performance.reduce((acc, obj) => {
                const category = obj["Tag 2"];
                const revenue = parseFloat(obj["Revenue"]);
                const revenueGain = parseFloat(obj["Yearly Revenue Gain In #1"]);
                if (!acc[category]) {
                  acc[category] = {
                    totalRevenue: 0,
                    totalRevenueGain: 0,
                  };
                }
                if (!isNaN(revenue)) acc[category].totalRevenue += revenue;
                if (!isNaN(revenueGain)) acc[category].totalRevenueGain += revenueGain;
                return acc;
              }, {});
              const revenueSorted = Object.keys(revenueStats).map(category => ({
                category,
                revenue: DecPrec.round(revenueStats[category].totalRevenue),
                revenue_gain: DecPrec.round(revenueStats[category].totalRevenueGain),
              })).sort((a, b) => b.revenue - a.revenue);
              if (revenueSorted.length > 0) {
                excelData["rto_revenue_sub"] = revenueSorted.slice(0, 10);
              }
            } catch (e) {
              console.error("RTO - Revenue by Sub-Cat", e.message)
            }

            // RTO - Revenue by Type
            try {
              const revenueStats = performance.reduce((acc, obj) => {
                const category = obj["Tag 3"];
                const revenue = parseFloat(obj["Revenue"]);
                const revenueGain = parseFloat(obj["Yearly Revenue Gain In #1"]);
                if (!acc[category]) {
                  acc[category] = {
                    totalRevenue: 0,
                    totalRevenueGain: 0,
                  };
                }
                if (!isNaN(revenue)) acc[category].totalRevenue += revenue;
                if (!isNaN(revenueGain)) acc[category].totalRevenueGain += revenueGain;
                return acc;
              }, {});
              const revenueSorted = Object.keys(revenueStats).map(category => ({
                category,
                revenue: DecPrec.round(revenueStats[category].totalRevenue),
                revenue_gain: DecPrec.round(revenueStats[category].totalRevenueGain),
              })).sort((a, b) => b.revenue - a.revenue);
              if (revenueSorted.length > 0) {
                excelData["rto_revenue_type"] = revenueSorted.slice(0, 10);
              }
            } catch (e) {
              console.error("RTO - Revenue by Type", e.message)
            }

            // UX - Category TBE
            try {
              const categoryTbeStats = performance.reduce((acc, obj) => {
                const category = obj["Keyword"] || "(blank))";
                const clicks = parseFloat(obj["Clicks"]);
                const exit_rate = parseFloat(obj["URL Exit Rate"]);
                const bounce_rate = parseFloat(obj["URL Bounce Rate"]);
                if (!acc[category]) {
                  acc[category] = {
                    totalClicks: 0,
                    totalExit: 0,
                    totalBounce: 0,
                    countExit: 0,
                    countBounce: 0
                  };
                }
                if (!isNaN(clicks)) acc[category].totalClicks += clicks;
                if (!isNaN(exit_rate)) {
                  acc[category].totalExit += exit_rate;
                  acc[category].countExit += 1;
                }
                if (!isNaN(bounce_rate)) {
                  acc[category].totalBounce += bounce_rate;
                  acc[category].countBounce += 1;
                }
                return acc;
              }, {});
              const categoryTbeSorted = Object.keys(categoryTbeStats).map(category => ({
                category,
                clicks: DecPrec.round(categoryTbeStats[category].totalClicks),
                exit_rate: parseFloat(DecPrec.toFixed(categoryTbeStats[category].totalExit / categoryTbeStats[category].countExit * 100, 2)),
                bounce_rate: parseFloat(DecPrec.toFixed(categoryTbeStats[category].totalBounce / categoryTbeStats[category].countBounce * 100, 2)),
              })).sort((a, b) => b.clicks - a.clicks);
              if (categoryTbeSorted.length > 0) {
                excelData["ux_category_tbe"] = categoryTbeSorted.slice(0, 20);
              }
            } catch (e) {
              console.error("UX - Category TBE", e.message)
            }

            // UX - Category T V CVR
            try {
              const categoryTcvrStats = performance.reduce((acc, obj) => {
                const category = obj["Keyword"] || "(blank))";
                const clicks = parseFloat(obj["Clicks"]);
                const cvr = parseFloat(obj["URL CVR"]);
                if (!acc[category]) {
                  acc[category] = {
                    totalClicks: 0,
                    totalCvr: 0,
                    countCvr: 0,
                  };
                }
                if (!isNaN(clicks)) acc[category].totalClicks += clicks;
                if (!isNaN(cvr)) {
                  acc[category].totalCvr += cvr;
                  acc[category].countCvr += 1;
                }
                return acc;
              }, {});
              const categoryTcvrSorted = Object.keys(categoryTcvrStats).map(category => ({
                category,
                clicks: DecPrec.round(categoryTcvrStats[category].totalClicks),
                cvr: parseFloat(DecPrec.toFixed(categoryTcvrStats[category].totalCvr / categoryTcvrStats[category].countCvr * 100, 2)),
              })).sort((a, b) => b.clicks - a.clicks);
              if (categoryTcvrSorted.length > 0) {
                excelData["ux_category_tcvr"] = categoryTcvrSorted.slice(0, 20);
              }
            } catch (e) {
              console.error("UX - Category T V CVR", e.message)
            }

            // UX - PT TBE
            try {
              const ptTbeStats = performance.reduce((acc, obj) => {
                const template = obj["Page Template"] || "(blank))";
                const clicks = parseFloat(obj["Clicks"]);
                const exit_rate = parseFloat(obj["URL Exit Rate"]);
                const bounce_rate = parseFloat(obj["URL Bounce Rate"]);
                if (!acc[template]) {
                  acc[template] = {
                    totalClicks: 0,
                    totalExit: 0,
                    totalBounce: 0,
                    countExit: 0,
                    countBounce: 0
                  };
                }
                if (!isNaN(clicks)) acc[template].totalClicks += clicks;
                if (!isNaN(exit_rate)) {
                  acc[template].totalExit += exit_rate;
                  acc[template].countExit += 1;
                }
                if (!isNaN(bounce_rate)) {
                  acc[template].totalBounce += bounce_rate;
                  acc[template].countBounce += 1;
                }
                return acc;
              }, {});
              const ptTbeSorted = Object.keys(ptTbeStats).map(template => ({
                template,
                clicks: DecPrec.round(ptTbeStats[template].totalClicks),
                exit_rate: parseFloat(DecPrec.toFixed(ptTbeStats[template].totalExit / ptTbeStats[template].countExit * 100, 2)),
                bounce_rate: parseFloat(DecPrec.toFixed(ptTbeStats[template].totalBounce / ptTbeStats[template].countBounce * 100, 2)),
              })).sort((a, b) => b.clicks - a.clicks);
              if (ptTbeSorted.length > 0) {
                excelData["ux_template_tbe"] = ptTbeSorted.slice(0, 20);
              }
            } catch (e) {
              console.error("UX - PT TBE", e.message)
            }

            // UX - PT T V CVR
            try {
              const ptTcvrStats = performance.reduce((acc, obj) => {
                const template = obj["Page Template"] || "(blank)";
                const clicks = parseFloat(obj["Clicks"]);
                const cvr = parseFloat(obj["URL CVR"]);
                if (!acc[template]) {
                  acc[template] = {
                    totalClicks: 0,
                    totalCvr: 0,
                    countCvr: 0,
                  };
                }
                if (!isNaN(clicks)) acc[template].totalClicks += clicks;
                if (!isNaN(cvr)) {
                  acc[template].totalCvr += cvr;
                  acc[template].countCvr += 1;
                }
                return acc;
              }, {});
              const ptTcvrSorted = Object.keys(ptTcvrStats).map(template => ({
                template,
                clicks: DecPrec.round(ptTcvrStats[template].totalClicks),
                cvr: parseFloat(DecPrec.toFixed(ptTcvrStats[template].totalCvr / ptTcvrStats[template].countCvr * 100, 2)),
              })).sort((a, b) => b.clicks - a.clicks);
              if (ptTcvrSorted.length > 0) {
                excelData["ux_template_tcvr"] = ptTcvrSorted.slice(0, 20);
              }
            } catch (e) {
              console.error("UX - PT T V CVR", e.message)
            }

            // CP - Estimated Traffic
            try {
              const competitorClicks = {
                "Competitor #1": 0,
                "Competitor #2": 0,
                "Competitor #3": 0,
                "Competitor #4": 0
              };
              performance.forEach(obj => {
                competitorClicks["Competitor #1"] += parseFloat(obj["Competitor #1 Clicks"]) || 0;
                competitorClicks["Competitor #2"] += parseFloat(obj["Competitor #2 Clicks"]) || 0;
                competitorClicks["Competitor #3"] += parseFloat(obj["Competitor #3 Clicks"]) || 0;
                competitorClicks["Competitor #4"] += parseFloat(obj["Competitor #4 Clicks"]) || 0;
              });
              const totalCompetitorClicks = Object.values(competitorClicks).reduce((acc, clicks) => acc + clicks, 0);
              const result = Object.keys(competitorClicks).map(competitor => ({
                websites: competitor,
                clicks: competitorClicks[competitor],
                share: parseFloat(DecPrec.toFixed((competitorClicks[competitor] / totalCompetitorClicks) * 100, 2))
              }));
              if (result[0].clicks !== 0 && result[1].clicks !== 0 && result[2].clicks !== 0 && result[3].clicks !== 0) {
                excelData["cp_traffic"] = result;
              }
            } catch (e) {
              console.error("CP - Estimated Traffic", e.message)
            }

            // CP - Comp Ranks
            try {
              const compRanksStats = performance.reduce((acc, obj) => {
                const category = obj["Tag 1"] || "(blank))";
                const client = parseFloat(obj["Client Ranking"]);
                const comp1 = parseFloat(obj["Competitor #1 Ranking"]);
                const comp2 = parseFloat(obj["Competitor #2 Ranking"]);
                const comp3 = parseFloat(obj["Competitor #3 Ranking"]);
                const comp4 = parseFloat(obj["Competitor #4 Ranking"]);
                if (!acc[category]) {
                  acc[category] = {
                    totalClient: 0,
                    totalComp1: 0,
                    totalComp2: 0,
                    totalComp3: 0,
                    totalComp4: 0,
                    countClient: 0,
                    countComp1: 0,
                    countComp2: 0,
                    countComp3: 0,
                    countComp4: 0,
                  };
                }
                if (!isNaN(client)) {
                  acc[category].totalClient += client;
                  acc[category].countClient += 1;
                }
                if (!isNaN(comp1)) {
                  acc[category].totalComp1 += comp1;
                  acc[category].countComp1 += 1;
                }
                if (!isNaN(comp2)) {
                  acc[category].totalComp2 += comp2;
                  acc[category].countComp2 += 1;
                }
                if (!isNaN(comp3)) {
                  acc[category].totalComp3 += comp3;
                  acc[category].countComp3 += 1;
                }
                if (!isNaN(comp4)) {
                  acc[category].totalComp4 += comp4;
                  acc[category].countComp4 += 1;
                }
                return acc;
              }, {});
              const compRanksSorted = Object.keys(compRanksStats).map(category => ({
                category,
                "Client": parseFloat(DecPrec.toFixed(compRanksStats[category].totalClient / compRanksStats[category].countClient, 2)),
                "Competitor #1": parseFloat(DecPrec.toFixed(compRanksStats[category].totalComp1 / compRanksStats[category].countComp1, 2)),
                "Competitor #2": parseFloat(DecPrec.toFixed(compRanksStats[category].totalComp2 / compRanksStats[category].countComp2, 2)),
                "Competitor #3": parseFloat(DecPrec.toFixed(compRanksStats[category].totalComp3 / compRanksStats[category].countComp3, 2)),
                "Competitor #4": parseFloat(DecPrec.toFixed(compRanksStats[category].totalComp4 / compRanksStats[category].countComp4, 2)),
              })).sort((a, b) => b["Client"] - a["Client"]);
              if (compRanksSorted.length > 0) {
                excelData["cp_comp_rankings"] = compRanksSorted.slice(0, 10);
              }
            } catch (e) {
              console.error("CP - Comp Ranks", e.message)
            }

            // CP - Comp Traffic
            try {
              const compTrafficStats = performance.reduce((acc, obj) => {
                const category = obj["Tag 1"] || "(blank))";
                const client = parseFloat(obj["Clicks"]);
                const comp1 = parseFloat(obj["Competitor #1 Clicks"]);
                const comp2 = parseFloat(obj["Competitor #2 Clicks"]);
                const comp3 = parseFloat(obj["Competitor #3 Clicks"]);
                const comp4 = parseFloat(obj["Competitor #4 Clicks"]);
                if (!acc[category]) {
                  acc[category] = {
                    client: 0,
                    comp1: 0,
                    comp2: 0,
                    comp3: 0,
                    comp4: 0,
                  };
                }
                if (!isNaN(client)) acc[category].client += client;
                if (!isNaN(comp1)) acc[category].comp1 += comp1;
                if (!isNaN(comp2)) acc[category].comp2 += comp2;
                if (!isNaN(comp3)) acc[category].comp3 += comp3;
                if (!isNaN(comp4)) acc[category].comp4 += comp4;
                return acc;
              }, {});
              const compTrafficSorted = Object.keys(compTrafficStats).map(category => ({
                category,
                "Client": DecPrec.round(compTrafficStats[category].client),
                "Competitor #1": DecPrec.round(compTrafficStats[category].comp1),
                "Competitor #2": DecPrec.round(compTrafficStats[category].comp2),
                "Competitor #3": DecPrec.round(compTrafficStats[category].comp3),
                "Competitor #4": DecPrec.round(compTrafficStats[category].comp4),
              })).sort((a, b) => b["Client"] - a["Client"]);
              if (compTrafficSorted.length > 0) {
                excelData["cp_comp_traffic"] = compTrafficSorted.slice(0, 20);
              }
            } catch (e) {
              console.error("CP - Comp Traffic", e.message)
            }

            // CP - Comp Revenue
            try {
              const compRevenueStats = performance.reduce((acc, obj) => {
                const category = obj["Tag 1"] || "(blank))";
                const client = parseFloat(obj["Revenue"]);
                const comp1 = parseFloat(obj["Competitor #1 Revenue"]);
                const comp2 = parseFloat(obj["Competitor #2 Revenue"]);
                const comp3 = parseFloat(obj["Competitor #3 Revenue"]);
                const comp4 = parseFloat(obj["Competitor #4 Revenue"]);
                if (!acc[category]) {
                  acc[category] = {
                    client: 0,
                    comp1: 0,
                    comp2: 0,
                    comp3: 0,
                    comp4: 0,
                  };
                }
                if (!isNaN(client)) acc[category].client += client;
                if (!isNaN(comp1)) acc[category].comp1 += comp1;
                if (!isNaN(comp2)) acc[category].comp2 += comp2;
                if (!isNaN(comp3)) acc[category].comp3 += comp3;
                if (!isNaN(comp4)) acc[category].comp4 += comp4;
                return acc;
              }, {});
              const compRevenueSorted = Object.keys(compRevenueStats).map(category => ({
                category,
                "Client": parseFloat(DecPrec.toFixed(compRevenueStats[category].client, 2)),
                "Competitor #1": parseFloat(DecPrec.toFixed(compRevenueStats[category].comp1, 2)),
                "Competitor #2": parseFloat(DecPrec.toFixed(compRevenueStats[category].comp2, 2)),
                "Competitor #3": parseFloat(DecPrec.toFixed(compRevenueStats[category].comp3, 2)),
                "Competitor #4": parseFloat(DecPrec.toFixed(compRevenueStats[category].comp4, 2)),
              })).sort((a, b) => b["Client"] - a["Client"]);
              if (compRevenueSorted.length > 0) {
                excelData["cp_comp_revenue"] = compRevenueSorted.slice(0, 20);
              }
            } catch (e) {
              console.error("CP - Comp Revenue", e.message)
            }

            // FF - SERP Result Type
            try {
              const resultTypeStats = serpReport.filter((obj) => obj["Result Type"] !== "-").reduce((acc, obj) => {
                const resultType = obj["Result Type"];
                if (!acc[resultType]) {
                  acc[resultType] = {
                    count: 0
                  };
                }
                acc[resultType].count += 1;
                return acc;
              }, {});
              const totalCount = Object.values(resultTypeStats).reduce((acc, stat) => acc + stat.count, 0);
              const ffTypeSorted = Object.keys(resultTypeStats).map(resultType => ({
                  feature: resultType,
                  count: resultTypeStats[resultType].count,
                  share: parseFloat(DecPrec.toFixed((resultTypeStats[resultType].count / totalCount) * 100, 2))
              })).sort((a, b) => b.count - a.count);
              if (ffTypeSorted.length > 0) {
                excelData["ff_type"] = ffTypeSorted;
              }
            } catch (e) {
              console.error("FF - SERP Result Type", e.message)
            }

            // FF - Owned SERP Features
            try {
              const ownedStats = serpReport.filter((obj) => obj["Result Type"] !== "-").reduce((acc, obj) => {
                const resultType = obj["Result Type"];
                const clientOwned = obj["Client Owned"];
                if (!acc[resultType]) {
                  acc[resultType] = {
                    count: 0,
                    clientOwned: 0
                  };
                }
                acc[resultType].count += 1;
                if (!isNaN(clientOwned)) acc[resultType].clientOwned += clientOwned;
                return acc;
              }, {});
              const ownedSorted = Object.keys(ownedStats).map(resultType => ({
                  feature: resultType,
                  count: ownedStats[resultType].count,
                  client_owned: ownedStats[resultType].clientOwned,
              })).sort((a, b) => b.count - a.count);
              if (ownedSorted.length > 0) {
                excelData["ff_owned"] = ownedSorted;
              }
            } catch (e) {
              console.error("FF - Owned SERP Features", e.message)
            }

            // FF - Competitor SERP Features
            try {
              const ffCompStats = serpReport.filter((obj) => obj["Domain"] !== "-").reduce((acc, obj) => {
                const domain = obj["Domain"];
                if (!acc[domain]) {
                  acc[domain] = {
                    count: 0
                  };
                }
                acc[domain].count += 1;
                return acc;
              }, {});
              const totalCount = Object.values(ffCompStats).reduce((acc, stat) => acc + stat.count, 0);
              const ffCompSorted = Object.keys(ffCompStats).map(domain => ({
                  website: domain,
                  share: parseFloat(DecPrec.toFixed((ffCompStats[domain].count / totalCount) * 100, 2))
              })).sort((a, b) => b.share - a.share);
              if (ffCompSorted.length > 0) {
                excelData["ff_competitor"] = ffCompSorted.slice(0, 10);
              }
            } catch (e) {
              console.error("FF - Competitor SERP Features", e.message)
            }

            // HSC - Cat Breakdown
            try {
              const hcsCatBreak = kwTrends.reduce((acc, obj) => {
                const tag = obj["Tag 1"];
                const volume = parseFloat(obj["Avg. Monthly Volume"]);
                if (!isNaN(volume)) {
                  if (!acc[tag]) {
                    acc[tag] = 0;
                  }
                  acc[tag] += volume;
                }
                return acc;
              }, {});
              const sortedCat = Object.keys(hcsCatBreak).map(tag => ({
                category: tag,
                volume: hcsCatBreak[tag]
              })).sort((a, b) => b.volume - a.volume).slice(0, 10);
              if (sortedCat.length > 0) {
                excelData["hcs_cat_break"] = sortedCat;
              }
            } catch (e) {
              console.error("HSC - Cat Breakdown", e.message)
            }

            // HSC - Sub-Cat Breakdown
            try {
              const hcsSubCatBreak = kwTrends.reduce((acc, obj) => {
                const tag = obj["Tag 2"];
                const volume = parseFloat(obj["Avg. Monthly Volume"]);
                if (!isNaN(volume)) {
                  if (!acc[tag]) {
                    acc[tag] = 0;
                  }
                  acc[tag] += volume;
                }
                return acc;
              }, {});
              const sortedSubCat = Object.keys(hcsSubCatBreak).map(tag => ({
                category: tag,
                volume: hcsSubCatBreak[tag]
              })).sort((a, b) => b.volume - a.volume).slice(0, 10);
              if (sortedSubCat.length > 0) {
                excelData["hcs_sub_cat_break"] = sortedSubCat;
              }
            } catch (e) {
              console.error("HSC - Sub-Cat Breakdown", e.message)
            }

            // HSC - Location Breakdown
            try {
              const hcsLocationBreak = kwTrends.reduce((acc, obj) => {
                const tag = obj["Tag 3"];
                const volume = parseFloat(obj["Avg. Monthly Volume"]);
                if (!isNaN(volume)) {
                  if (!acc[tag]) {
                    acc[tag] = 0;
                  }
                  acc[tag] += volume;
                }
                return acc;
              }, {});
              const sortedLocation = Object.keys(hcsLocationBreak).map(tag => ({
                category: tag,
                volume: hcsLocationBreak[tag]
              })).sort((a, b) => b.volume - a.volume).slice(0, 10);
              if (sortedLocation.length > 0) {
                excelData["hcs_location_break"] = sortedLocation;
              }
            } catch (e) {
              console.error("HSC - Location Breakdown", e.message)
            }

            // HSC - Cat YoY
            try {
              const hcsCatYoy = kwTrends.reduce((acc, obj) => {
                const tag = obj["Tag 1"];
                const last = obj["Previous 12 Months"];
                const prev = obj["Last 12 Months"];
                  if (!acc[tag]) {
                    acc[tag] = {
                      "last": 0,
                      "prev": 0,
                    };
                  }
                  if (!isNaN(last)) acc[tag].last += last;
                  if (!isNaN(prev)) acc[tag].prev += prev;
                return acc;
              }, {});
              const sortedCatYoy = Object.keys(hcsCatYoy).map(tag => ({
                category: tag,
                last: hcsCatYoy[tag].last,
                prev: hcsCatYoy[tag].prev,
                yoy: parseFloat(DecPrec.toFixed((hcsCatYoy[tag].last - hcsCatYoy[tag].prev) / hcsCatYoy[tag].last * 100, 2))
              })).sort((a, b) => b.yoy - a.yoy).slice(0, 10);
              if (sortedCatYoy.length > 0) {
                excelData["hcs_cat_yoy"] = sortedCatYoy;
              }
            } catch (e) {
              console.error("HSC - Cat YoY", e.message)
            }

            // HSC - Sub-Cat YoY
            try {
              const hcsSubCatYoy = kwTrends.reduce((acc, obj) => {
                const tag = obj["Tag 2"];
                const last = obj["Previous 12 Months"];
                const prev = obj["Last 12 Months"];
                  if (!acc[tag]) {
                    acc[tag] = {
                      "last": 0,
                      "prev": 0,
                    };
                  }
                  if (!isNaN(last)) acc[tag].last += last;
                  if (!isNaN(prev)) acc[tag].prev += prev;
                return acc;
              }, {});
              const sortedSubCatYoy = Object.keys(hcsSubCatYoy).map(tag => ({
                category: tag,
                last: hcsSubCatYoy[tag].last,
                prev: hcsSubCatYoy[tag].prev,
                yoy: parseFloat(DecPrec.toFixed((hcsSubCatYoy[tag].last - hcsSubCatYoy[tag].prev) / hcsSubCatYoy[tag].last * 100, 2))
              })).sort((a, b) => b.yoy - a.yoy).slice(0, 10);
              if (sortedSubCatYoy.length > 0) {
                excelData["hcs_sub_cat_yoy"] = sortedSubCatYoy;
              }
            } catch (e) {
              console.error("HSC - Sub-Cat YoY", e.message)
            }

            // HSC - Location YoY
            try {
              const hcsLocationYoy = kwTrends.reduce((acc, obj) => {
                const tag = obj["Tag 3"];
                const last = obj["Previous 12 Months"];
                const prev = obj["Last 12 Months"];
                if (!acc[tag]) {
                  acc[tag] = {
                    "last": 0,
                    "prev": 0,
                  };
                }
                if (!isNaN(last)) acc[tag].last += last;
                if (!isNaN(prev)) acc[tag].prev += prev;
                return acc;
              }, {});
              const sortedLocationYoy = Object.keys(hcsLocationYoy).map(tag => ({
                category: tag,
                last: hcsLocationYoy[tag].last,
                prev: hcsLocationYoy[tag].prev,
                yoy: parseFloat(DecPrec.toFixed((hcsLocationYoy[tag].last - hcsLocationYoy[tag].prev) / hcsLocationYoy[tag].last * 100, 2))
              })).sort((a, b) => b.yoy - a.yoy).slice(0, 10);
              if (sortedLocationYoy.length > 0) {
                excelData["hcs_location_yoy"] = sortedLocationYoy;
              }
            } catch (e) {
              console.error("HSC - Location YoY", e.message)
            }

            console.log(excelData);
          }
        };
        reader.readAsArrayBuffer(file);
      }
      catch {
        setUploadedFile(undefined);
        setErrorMessage('Something went wrong.');
      }
    }
    else {
      setUploadedFile(undefined);
      setErrorMessage('')
    }
  };

  const [title, setTitle] = useState('');
  const [client, setClient] = useState('');
  const [rootDomain, setRootDomain] = useState('');
  const [industry, setIndustry] = useState('');
  const [currency, setCurrency] = useState('');

  const currencies = [
    { value: 'EUR (€)' },
    { value: 'GBP (£)' },
    { value: 'USD ($)' }
  ];
  const industries = [
    { value: 'Fashion/Retail' }
  ];

  const clientObj = () => {
    if(clients.length > 0) {
      return clients.map(item => ({ 'value': item }))
    }
    else {
      return [];
    }
  };

  const nextDisabled = title == '' || client === '' || rootDomain === '' || industry === '' || currency === '';

  const [loading, setLoading] = useState(false)

  const [modalStep, setModalStep] = useState(1);
  const incModalStep = () => setModalStep(modalStep => modalStep + 1);
  const decModalStep = () => setModalStep(modalStep => modalStep - 1);

  const { newReport } = useReports();
  const [reportData, setReportData] = useState();

  const { authState } = useOktaAuth();

  const history = useNavigate();

  const createReport = async () => {
    incModalStep();
    
    const info = {
      "title": title,
      "industry": industry,
      "currency": currency,
      "client": client,
      "rootDomain": rootDomain
    };

    // const report = { ...info, ...reportData }

    const formData = new FormData();
    formData.append('file', uploadedFile);
    for (const key in info) {
      formData.append(key, info[key]);
    }
    
    let report = await newReport(formData);
    if (report.status == 200 ) {
      history(`/report/${report.data.id}/report-overview`);
    }
    else {
      decModalStep();
      setUploadedFile(undefined);
      setErrorMessage('File is empty');
    }
  }

  return (
    <>
      {modalStep < 3 && (
        <>
          <div className="modal-main">
            <div className="modal-title">
              <div className="pagination">Step <span className="purple">{modalStep}</span> of 2</div>
              <h2>{modalStep === 1 ? "Create New Report" : "Data Upload"}</h2>
            </div>
            <div className="modal-main-inner">
              <div className="form">
                <form className="new-report">
                  {modalStep === 1 && (
                    <>
                      <div className="top-level-metrics">
                        <div className="dropdowns nowrap">
                          <InputText value={title} onChange={(e) => setTitle(e.target.value)} 
                            placeholder="Enter Report Title" className="input-title"/>
                          <InputText value={rootDomain} onChange={(e) => setRootDomain(e.target.value)} 
                            placeholder="Enter Client Root Domain" className="input-rootDomain"/>
                        </div>
                        <div className="dropdowns">
                          <Dropdown value={client} onChange={(e) => setClient(e.value)} options={clientObj()}
                            optionLabel="value" placeholder="Select Client" className="input-client"/>
                          <Dropdown value={industry} options={industries} onChange={(e) => setIndustry(e.value)}
                            optionLabel="value" placeholder="Select Industry" className="input-industry" />
                          <Dropdown value={currency} options={currencies} onChange={(e) => setCurrency(e.value)}
                            optionLabel="value" placeholder="Select Currency" className="input-currency"/>
                        </div>
                      </div>
                      <DownloadButton
                        file={templateFile}
                        style={{margin: "auto"}}
                        text={"Download Data Template"}
                      />
                    </>         
                  )}
                  {modalStep === 2 && (
                    <>
                      <div className="report-info">
                        <div className="report-info__item">
                          <div className="report-info__item__label">Report Name</div>
                          <div className="report-info__item__value">{title}</div>
                        </div>
                        <div className="report-info__item">
                          <div className="report-info__item__label">Client</div>
                          <div className="report-info__item__value">{client}</div>
                        </div>
                        <div className="report-info__item">
                          <div className="report-info__item__label">Root Domain</div>
                          <div className="report-info__item__value">{rootDomain}</div>
                        </div>
                        <div className="report-info__item">
                          <div className="report-info__item__label">Industry</div>
                          <div className="report-info__item__value">{industry}</div>
                        </div>
                        <div className="report-info__item">
                          <div className="report-info__item__label">Currency</div>
                          <div className="report-info__item__value">{currency}</div>
                        </div>
                      </div>
                      <div className="data-upload">
                        <p className="bold">Upload your populated data template here to generate report.</p>
                        <FilePond
                          credits={false} 
                          // acceptedFileTypes={['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel.sheet.macroEnabled.12']}
                          labelIdle='Drag & Drop your file here or <br> <span class="filepond--label-action">click here to browse</span>'
                          files={uploadedFile ? [uploadedFile] : []}
                          onaddfile={handleFileLoad}
                        />
                        {errorMessage && <p style={{ color: 'red', marginTop: 0 }}>{errorMessage}</p>}
                      </div>
                    </>
                  )}
                </form>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <div className="close-modal">
              <Button
                className={"cancel-button border"}
                onClick={closeModal}
                variant="secondary"
              >
                Cancel
              </Button>
            </div>
            {modalStep === 1 && (
                <Button
                  onClick={incModalStep}
                  disabled={nextDisabled}
                >
                  Next
                </Button>
            )}
            {modalStep === 2 && (
              <div className="group">
                <div className="back-step">
                  <Button
                    onClick={decModalStep}
                  >
                    Back
                  </Button>
                </div>
                <div className="create-report">
                  <Button
                    disabled={createReportDisabled}
                    onClick={createReport}
                  >
                    Create Report
                  </Button>
                </div>
              </div>
            )}
          </div>
        </>
      )}
      {modalStep === 3 && (
        <div className="modal-main">
          <div className="modal-main-inner">
            <ProgressBar mode="indeterminate" style={{ height: '6px' }}></ProgressBar>
          </div>
        </div>
      )}
    </>
  );
}
