import { useOktaAuth } from "@okta/okta-react";
import axios from "axios";
import { useState } from "react";

let API_URL;
if(process.env.NODE_ENV === "development") {
  API_URL = "http://localhost:8080/api";
}
else {
  API_URL = "/api";
}

export default function useReports() {
  const [isLoading, setIsLoading] = useState(false);
  const [reports, setReports] = useState([]);
  const [error, setError] = useState(null);
  const { authState } = useOktaAuth();

  const oktaToken = authState.accessToken.accessToken;

  const apiClient = axios.create({
    baseURL: API_URL,
    withCredentials: false,
    headers: {
      Authorization: `Bearer ${oktaToken}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });

  const getReports = async (params) => {
    try {
      setIsLoading(true);
      setError(null);

      let url = "/reports";

      if (params) {
        const uriComponent = Object.keys(params).reduce((previous, key) => {
          return (previous += `${previous ? "&" : "?"}${key}=${params[key]}`);
        }, "");

        url += uriComponent;
      }

      const response = await apiClient.get(url);

      return response.data;
    } catch (error) {
      console.error(error);
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getReport = async (id) => {
    try {
      setIsLoading(true);
      setError(null);

      const response = await apiClient.get(`/report?id=${id}`);
      console.log(response.data);
      return response.data;
    } catch (error) {
      console.error(error);
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const deleteReport = async (id) => {
    try {
      setIsLoading(true);
      setError(null);

      const response = await apiClient.get(`/delete_report?id=${id}`);

      return response;
    } catch (error) {
      console.error(error);
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };
  
  const newReport = async (data) => {
    try {
      setIsLoading(true);
      setError(null);

      return await apiClient.post('/new_report', data, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
    } catch (error) {
      console.error(error);
      setError(error);
      return error;
    } finally {
      setIsLoading(false);
    }
  };
  
  const updateCommentary = async (data) => {
    try {
      setIsLoading(true);
      setError(null);

      return await apiClient.post('/update_commentary', data);
    } catch (error) {
      console.error(error);
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  return { isLoading, error, reports, getReports, getReport, setReports, newReport, deleteReport, updateCommentary };
}
